.form-check-label {
  font-weight: 400;
}

.document-form .form-control {
  border: 0;
  border-bottom: 1px dashed #444;
  text-transform: uppercase;
}

.document-form label {
  color: #666;
}

.document-form .form-control:focus {
  box-shadow: none;
}

.react-datepicker-wrapper, .react-datepicker__input-container {
  width: 100%;
}

.react-datepicker__time-container {

}

.file-input {
  display: none;
}

.upload-button {
  cursor: pointer;
}