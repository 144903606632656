@import "./scss/common/table.css";
@import "./scss/common/btn.css";
@import "./scss/common/list.css";
@import "./scss/common/form.css";
@import "./scss/common/card.css";
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

.body-content {
  min-height: 86vh;
  overflow-y: scroll;
  margin-top: 30px;
}

h5 {
  margin-top: 20px;
}

label {
  font-size: 12px;
  margin-bottom: 0;
  font-weight: 600;
}

.img-thumbnail {
  border: 5px solid #eee;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
}

.google-places-autocomplete__input {
  box-shadow: none !important;
  font: inherit;
  color: currentColor;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  border-radius: 5px !important;
  width: 100%;
  margin: 0;
  padding: 6px 0 7px;
  display: block;
  min-width: 0;
  box-sizing: content-box;
  background: none;
  margin-left: 8px !important;
  margin-right: 8px !important;
}