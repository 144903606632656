.login {
  text-align: center;
}

.login-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 30px;
}

@keyframes login-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

strong {
  color: #fff;
}