.body-content {
  margin-top: 0;
}

.documents {
  padding: 10px;
  background-color: #eef1f5;
}

.program-details {
  max-height: 85vh;
  padding: 0;
  overflow-y: scroll;
  background-color: #fff;
}

.program-documents {
  padding: 10px;
}