.card-img-top {
}

.card {
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.06);
}

.card-body {
    padding: 10px;
}

.card-header h6 {
    margin-bottom: 0;
    margin-top: 5px;
    text-transform: uppercase;
}

.card .list-group {
}

.card .list-group .list-group-item {
}


.card .card-img-top {
    max-height: 200px;
}